import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';
import { RadioButton, Select } from 'sw-ui';

import { getMoment, isAfterDate } from '../../../../bi/utils/formatDate';

import DecisionButtons from '../../../../components/DecisionButtons';
import Input from '../../../../components/input';
import TextArea from '../../../../components/textarea';

import { DECISIONBUTTONS } from '../../../../bi/constants/decisionButtons';
import { FIELDS_FORM, FIELDS_FORM_RU } from '../../../../bi/constants/profile';
import { DEFAULTDATE } from '../../../../constants/time';
import COMPONENTS from '../../../../bi/constants/components';

import styles from '../../styles/company.module.scss';

const { SELECT: { THEME: { BORDER } } } = COMPONENTS;

const LABELS = {
  NAME: 'Имя',
  FAMILY: 'Фамилия',
  PATRONOMIC: 'Отчество',
  UR_HEAD: 'Юр. лицо',
  IP: 'ИП',
  ACCOUNT_TYPE: 'Признак подаккаунта',
  REQUIRED_ARG: 'Требуется заполнение реквизитов компании',
  EDITING_ARG: 'Редактирование реквизитов компании',
};

const COMPANY_TYPE = {
  UL: 'UL',
  IP: 'IP',
};

class CompanyInfoForm extends Component {
  static propTypes = {
    details: PropTypes.object.isRequired,
    AccountTypeList: PropTypes.array.isRequired,
    companyService: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    fromUpd: PropTypes.bool,

    error: PropTypes.any,
    isSmartwayKZ: PropTypes.bool.isRequired,
  };

  static defaultProps = { fromUpd: false };

  constructor(props) {
    super(props);

    const apply = props.details.CustomerType ? COMPANY_TYPE.IP : COMPANY_TYPE.UL;

    this.state = {
      AccountTypeList: props.AccountTypeList,
      CompanyTypeId: props.details.CompanyTypeId,
      details: {
        CompanyId: props.details.CompanyId,
        CompanyName: props.details.CompanyName,
        ShortCompanyName: props.details.ShortCompanyName,
        INN: props.details.INN,
        Contract: props.details.Contract,
        KPP: props.details.KPP,
        LegalAddress: props.details.LegalAddress,
        ActualAddress: props.details.ActualAddress,
        CEO: props.details.CEO,
        Index: props.details.Index,
        RegionCode: props.details.RegionCode,
        District: props.details.District,
        City: props.details.City,
        Locality: props.details.Locality,
        Street: props.details.Street,
        Structure: props.details.Structure,
        Housing: props.details.Housing,
        Room: props.details.Room,
        FirstName: props.details.FirstName,
        LastName: props.details.LastName,
        Patronymic: props.details.Patronymic,
        CustomerType: props.details.CustomerType,
        IINBIN: props.details.IINBIN,
      },
      error: props.error,
      waitingResponse: false,
      applyField: props.isSmartwayKZ ? null : apply,
      PrevContract: { ...props.details.Contract },

      validation: {
        CompanyName: '',
        INN: '',
        KPP: '',
        ContractNumber: '',
        ContractStartDate: '',
        Index: '',
        RegionCode: '',
        BIN: '',
        IIN: '',
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ error: nextProps.error });
  }

  handlerChangeState = (value, field) => {
    this.setState({
      details: {
        ...this.state.details,
        [field]: value,
      },
    });
  };

  handlerChangeInput = ({ target: { value } }, field) => {
    if ((field === FIELDS_FORM.INN && value.length > 12)
      || (field === FIELDS_FORM.KPP && value.length > 9)
      || (field === FIELDS_FORM.INDEX && value.length > 6)
      || (field === FIELDS_FORM.REGION_CODE && value.length > 2)
      || (field === FIELDS_FORM.DISTRICT && value.length > 50)
      || (field === FIELDS_FORM.CITY && value.length > 50)
      || (field === FIELDS_FORM.LOCALITY && value.length > 50)
      || (field === FIELDS_FORM.STREET && value.length > 50)
      || (field === FIELDS_FORM.STRUCTURE && value.length > 20)
      || (field === FIELDS_FORM.HOUSING && value.length > 20)
      || (field === FIELDS_FORM.ROOM && value.length > 20)
      || (field === FIELDS_FORM.IIN_BIN_KZ && value.length > 12)) return;

    this.handlerChangeState(value, field);
  };

  handlerFocusInput = () => {
    this.setState({ error: null });
  };

  handleChangeContract = (value, field) => this.setState({
    details: {
      ...this.state.details,
      Contract: {
        ...this.state.details.Contract,
        [field]: value,
      },
    },
  });

  handleChangeSelect = ({ value }) => (
    this.setState({
      ...this.state,
      CompanyTypeId: value,
    })
  );

  validationOnSave = () => {
    const { details, CompanyTypeId } = this.state;
    const { isValid, validation } = this.validationSave({ details });

    const onSave = () => {
      this.props.onSave({ ...details, CompanyTypeId })
        .catch(() => {
          this.setState({ waitingResponse: false });
        });
    };

    if (isValid) {
      this.setState({ waitingResponse: true }, onSave);
    } else {
      this.setState({ validation: { ...validation } });
    }
  };

  validationSave = ({ details }) => {
    const { companyService, isSmartwayKZ } = this.props;
    const { PrevContract, AccountTypeList } = this.state;

    const { infoCompanyFieldValidation } = companyService;
    const {
      Contract, CompanyName, INN, IINBIN, KPP, Index, RegionCode,
    } = details;
    const isBlankPrevContract = (PrevContract && !PrevContract.Number && !PrevContract.StartDate)
      || !(PrevContract && PrevContract.Number !== Contract.Number)
      || !(PrevContract && PrevContract.StartDate !== Contract.StartDate);

    const contractNumber = (Contract && Contract.StartDate) && PrevContract.StartDate !== Contract.StartDate ? infoCompanyFieldValidation(FIELDS_FORM.CONTRACT_NUMBER, { prev: PrevContract.Number, current: Contract.Number }) : '';
    const contractStartDate = Contract && !!Contract.Number && ((PrevContract.Number !== Contract.Number || PrevContract.StartDate !== Contract.StartDate)) ? infoCompanyFieldValidation(FIELDS_FORM.CONTRACT_START_DATE, Contract.StartDate) : '';
    const prevContractDate = !isBlankPrevContract ? infoCompanyFieldValidation(FIELDS_FORM.CONTRACT_START_DATE, Contract.StartDate) : '';
    const prevContractNumber = !isBlankPrevContract ? infoCompanyFieldValidation(FIELDS_FORM.CONTRACT_NUMBER, { prev: PrevContract.Number, current: Contract.Number }) : '';

    const companyName = infoCompanyFieldValidation(FIELDS_FORM.COMPANY_NAME, CompanyName);
    const inn = !isSmartwayKZ ? infoCompanyFieldValidation(FIELDS_FORM.INN, INN) : '';
    const kpp = KPP && !isSmartwayKZ ? infoCompanyFieldValidation(FIELDS_FORM.KPP, KPP) : '';
    const iin_bin = isSmartwayKZ && infoCompanyFieldValidation(FIELDS_FORM.IIN_BIN_KZ, IINBIN);

    const index = infoCompanyFieldValidation(FIELDS_FORM.INDEX, Index);
    const regionCode = infoCompanyFieldValidation(FIELDS_FORM.REGION_CODE, RegionCode);

    return companyName
      || inn
      || kpp
      || iin_bin
      || index
      || regionCode
      || contractNumber
      || contractStartDate
      || prevContractDate
      || !AccountTypeList
      || prevContractNumber
      ? {
        isValid: false,
        validation: {
          CompanyName: companyName,
          INN: inn,
          KPP: kpp,
          IINBIN: iin_bin,
          Index: index,
          RegionCode: regionCode,
          ContractNumber: contractNumber || prevContractNumber,
          ContractStartDate: contractStartDate || prevContractDate,
        },
      }
      : { isValid: true };
  };

  validationInput = (e, field) => {
    const { companyService } = this.props;

    this.setState((state) => ({
      ...state,
      validation: {
        ...state.validation,
        [field]: companyService.infoCompanyFieldValidation(field, state.details[field]),
      },
    }));
  };

  getValidStartDate = (currentDate) => isAfterDate(getMoment(), currentDate);

  handleChange = (value) => {
    this.setState({
      details: {
        ...this.state.details,
        CustomerType: value === COMPANY_TYPE.UL ? 0 : 1,
      },
      applyField: value,
    });
  };

  renderIPExtraFields = () => {
    const { applyField } = this.state;
    const { isSmartwayKZ } = this.props;

    if (applyField !== COMPANY_TYPE.IP && !isSmartwayKZ) {
      return null;
    }

    return (
      <div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field='FirstName'
              type='text'
              value={ this.state.details.FirstName }
              label={ LABELS.NAME }
              onChange={ this.handlerChangeInput }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field='LastName'
              type='text'
              value={ this.state.details.LastName }
              label={ LABELS.FAMILY }
              onChange={ this.handlerChangeInput }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field='Patronymic'
              type='text'
              value={ this.state.details.Patronymic }
              label={ LABELS.PATRONOMIC }
              onChange={ this.handlerChangeInput }
            />
          </div>
        </div>
      </div>
    );
  };

  renderFieldWithTypeCompany = () => {
    const { isSmartwayKZ } = this.props;
    const {
      validation,
      details: {
        INN,
        KPP,
        IINBIN,
      },
    } = this.state;

    if (isSmartwayKZ) {
      return (
        <div>
          <div className={ styles.row }>
            <div className={ styles.col }>
              <Input
                field={ FIELDS_FORM.IIN_BIN_KZ }
                value={ IINBIN }
                label={ FIELDS_FORM_RU[FIELDS_FORM.IIN_BIN_KZ] }
                onChange={ this.handlerChangeInput }
                onBlur={ this.validationInput }
                valid={ validation.IINBIN }
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.INN }
              value={ INN }
              label={ FIELDS_FORM_RU[FIELDS_FORM.INN] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
              onBlur={ this.validationInput }
              valid={ validation.INN }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.KPP }
              value={ KPP }
              label={ FIELDS_FORM_RU[FIELDS_FORM.KPP] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
              onBlur={ this.validationInput }
              valid={ validation.KPP }
            />
          </div>
        </div>
      </div>
    );
  };

  renderSwitcherTypeCompany = () => {
    const { applyField } = this.state;
    const { isSmartwayKZ } = this.props;

    if (isSmartwayKZ) {
      return null;
    }

    return (
      <div className={ `${styles.row} ${styles.radio}` }>
        <RadioButton
          value={ COMPANY_TYPE.UL }
          checked={ applyField === COMPANY_TYPE.UL }
          onChange={ () => this.handleChange(COMPANY_TYPE.UL) }
        >
          { LABELS.UR_HEAD }
        </RadioButton>
        <RadioButton
          value={ COMPANY_TYPE.IP }
          checked={ applyField === COMPANY_TYPE.IP }
          onChange={ () => this.handleChange(COMPANY_TYPE.IP) }
        >
          { LABELS.IP }
        </RadioButton>
      </div>
    );
  };

  renderTypeAccount = () => {
    // const { isSmartwayKZ } = this.props;
    const { AccountTypeList, CompanyTypeId } = this.state;

    // if (isSmartwayKZ) {
    //   return null;
    // }
    // раскомментировать при релизе признака подакаунта KZ

    return (
      <div className={ `${styles.row} ${styles.select}` }>
        <Select
          label={ LABELS.ACCOUNT_TYPE }
          items={ AccountTypeList }
          theme={ BORDER }
          value={ `${CompanyTypeId}` }
          onChange={ this.handleChangeSelect }
        />
      </div>
    );
  };

  render() {
    const {
      error,
      validation,
      waitingResponse,
      details: {
        Contract: {
          Number,
          StartDate,
        },
        CompanyName,
        ShortCompanyName,
        LegalAddress,
        ActualAddress,
        CEO,
        Index,
        RegionCode,
        District,
        City,
        Locality,
        Street,
        Structure,
        Housing,
        Room,
      },
    } = this.state;

    const { ContractStartDate, ContractNumber } = validation;

    const titleHtml = this.props.fromUpd ? LABELS.REQUIRED_ARG : LABELS.EDITING_ARG;
    const validDateHtml = ContractStartDate && <span className={ styles['error-msg_contract'] }>{ ContractStartDate }</span>;

    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <div className={ `${styles.row} ${styles.title}` }>
          <div className={ styles.col }>{ titleHtml }</div>
        </div>
        <div className={ `${styles.row} ${styles.error} ${error ? styles.present : ''}` }>
          <div className={ styles.col }>
            { error }
          </div>
        </div>
        { this.renderSwitcherTypeCompany() }
        { this.renderTypeAccount() }
        { this.renderIPExtraFields() }
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.COMPANY_NAME }
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ CompanyName }
              label={ FIELDS_FORM_RU[FIELDS_FORM.COMPANY_NAME] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
              onBlur={ this.validationInput }
              valid={ validation.CompanyName }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.SHORT_COMPANY_NAME }
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ ShortCompanyName }
              label={ FIELDS_FORM_RU[FIELDS_FORM.SHORT_COMPANY_NAME] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.CONTRACT_NUMBER }
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ Number }
              label={ FIELDS_FORM_RU[FIELDS_FORM.CONTRACT_NUMBER] }
              onChange={ (e, field, value) => this.handleChangeContract(value, field) }
              onFocus={ this.handlerFocusInput }
              valid={ ContractNumber }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.label }>
            { ' ' }
            { FIELDS_FORM_RU[FIELDS_FORM.CONTRACT_START_DATE] }
          </div>
          <DatePicker
            dateFormat={ DEFAULTDATE }
            timeFormat=''
            value={ StartDate }
            onChange={ (value) => this.handleChangeContract(value, FIELDS_FORM.CONTRACT_START_DATE) }
            isValidDate={ this.getValidStartDate }
            className={ `componentHook ${ContractStartDate ? 'no-valid' : ''}` }
          />
          { validDateHtml }
        </div>
        { this.renderFieldWithTypeCompany() }
        <div className={ styles.row }>
          <div className={ `${styles.col} ${styles.textarea}` }>
            <TextArea
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ LegalAddress }
              label={ FIELDS_FORM_RU[FIELDS_FORM.LEGAL_ADDRESS] }
              onChange={ (value) => this.handlerChangeState(value, FIELDS_FORM.LEGAL_ADDRESS) }
              onFocus={ this.handlerFocusInput }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ `${styles.col} ${styles.textarea}` }>
            <TextArea
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ ActualAddress }
              label={ FIELDS_FORM_RU[FIELDS_FORM.ACTUAL_ADDRESS] }
              onChange={ (value) => this.handlerChangeState(value, FIELDS_FORM.ACTUAL_ADDRESS) }
              onFocus={ this.handlerFocusInput }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.CEO }
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ CEO }
              label={ FIELDS_FORM_RU[FIELDS_FORM.CEO] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.INDEX }
              value={ Index }
              label={ FIELDS_FORM_RU[FIELDS_FORM.INDEX] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
              onBlur={ this.validationInput }
              valid={ validation.Index }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.REGION_CODE }
              value={ RegionCode }
              label={ FIELDS_FORM_RU[FIELDS_FORM.REGION_CODE] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
              onBlur={ this.validationInput }
              valid={ validation.RegionCode }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.DISTRICT }
              value={ District }
              label={ FIELDS_FORM_RU[FIELDS_FORM.DISTRICT] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.CITY }
              value={ City }
              label={ FIELDS_FORM_RU[FIELDS_FORM.CITY] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.LOCALITY }
              value={ Locality }
              label={ FIELDS_FORM_RU[FIELDS_FORM.LOCALITY] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.STREET }
              value={ Street }
              label={ FIELDS_FORM_RU[FIELDS_FORM.STREET] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.STRUCTURE }
              value={ Structure }
              label={ FIELDS_FORM_RU[FIELDS_FORM.STRUCTURE] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.HOUSING }
              value={ Housing }
              label={ FIELDS_FORM_RU[FIELDS_FORM.HOUSING] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS_FORM.ROOM }
              value={ Room }
              label={ FIELDS_FORM_RU[FIELDS_FORM.ROOM] }
              onChange={ this.handlerChangeInput }
              onFocus={ this.handlerFocusInput }
            />
          </div>
        </div>
        <div className={ `${styles.row} ${styles.action}` }>
          <DecisionButtons
            waitingResponse={ waitingResponse }
            onSave={ this.validationOnSave }
            onCancel={ () => this.props.onClose() }
            labelSave={ DECISIONBUTTONS.LABELS.SAVE }
          />
        </div>
      </div>
    );
  }
}

export default CompanyInfoForm;
